import React from "react";
import johnsoncitymeta from "../assets/images/johnsoncitymeta.jpg";
import bgImage from "../assets/images/overlandpark5.jpg";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Container,
    Flex,
    Grid,
    Heading,
    Hide,
    Link,
    List,
    ListIcon,
    ListItem,
    Stack,
    Text,
    UnorderedList,
    VStack,
} from "@chakra-ui/react";
import {faqs} from "./faqs";
import {BiConversation, BiCut} from "react-icons/bi";
import {GiChoppedSkull, GiLandMine, GiStumpRegrowth, GiTreeBranch, GiTreeDoor, GiTreeRoots} from "react-icons/gi";
import {FaConciergeBell, FaRegHandScissors, FaStar, FaUserTie} from "react-icons/fa";
import {
    MdCheckCircle,
    MdEmergencyShare,
    MdHealthAndSafety,
    MdOutlineEmergency,
    MdOutlineNaturePeople
} from "react-icons/md";
import {AiOutlineScissor} from "react-icons/ai";
import {RiEmotionHappyLine} from "react-icons/ri";
import {PiClockCountdownFill} from "react-icons/pi";

const phoneNumber = "(423) 840-8746";
const telLink = "tel:4238408746";
const phoneNumberWithDashes = "423-840-8746";

export const SiteData = {
        hoursOfOperation: "24/7",
        city: 'Johnson City, TN',
        phoneNumber,
        phoneNumberWithDashes,
        telLink,
        buttonCtaText: () => <Flex>Ready for Expert Tree Care? Schedule Your&nbsp;<Text textDecoration={'underline'}
                                                                                        display={'inline'}>Free
            Consultation Today!</Text></Flex>,
        emailFooterText: `For all your Tree Service needs, call Johnson City Tree Service at ${phoneNumber}`,
        keywords: 'Tree Care, services, professional, affordable, experienced, trimming, Johnson City, TN.',
        footerText: 'Johnson City Tree Service, All rights reserved.',
        ogImage: johnsoncitymeta,
        navLinks: [
            {
                title: 'Home',
                href: '/'
            },
            {
                title: 'Services',
                href: '/services'
            },
            {
                title: 'Blog',
                href: '/blog'
            },
            {
                title: 'About',
                href: '/about'
            },
            {
                title: 'Contact Us',
                href: '/contact'
            },
            {
                title: 'Careers',
                href: '/careers',
                hideFromTopNav: true
            },
        ],
        signupForm: {
            intro: () => <Text>Welcome to Johnson City Tree Service, the premier tree service provider in Johnson City, TN.
                Our team of seasoned arborists understand the unique botany and landscaping of our beautiful region and we
                are delighted to bring this expertise to you through our diverse services. For the top-notch in tree
                service, reach out to us at <Link href={'tel:4238408746'}
                                                  textDecoration={'underline'}>(423) 840-8746</Link> today!
            </Text>,
            servicesTitle: 'Our Services:',
        },
        homepage: {
            bgImage: bgImage,
            url: 'https://www.johnsoncitytreeservice.com',
            metaData: {
                title: 'Top Tree Service in Johnson City, TN | Call (423) 840-8746',
                description: 'Premier tree service in Johnson City TN. Offering expert tree care, tree removal, and maintenance. Call today for professional Johnson City tree services.',
            },
            h1: 'Johnson City Tree Service - Leading Tree Care in Johnson City, TN',
            subtitle: 'Professional Tree Service Johnson City TN - Trimming, Removal, Health Consulting',
            servicesTitle: 'Why Choose Us for Tree Service in Johnson City, TN',
            values: [
                {
                    id: 0,
                    number: "5000+",
                    name: "Trees Serviced",
                    icon: GiTreeBranch,
                },
                {
                    "id": 4,
                    "number": <><Flex my={3}><FaStar fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/><FaStar
                        fontSize={'1.9rem'}/><FaStar
                        fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/></Flex></>,
                    "name": "Experienced Technicians",
                    "icon": FaUserTie
                },
                {
                    id: 2,
                    number: "98%",
                    name: "Customer Satisfaction",
                    icon: RiEmotionHappyLine,
                },
                {
                    id: 3,
                    number: "24h",
                    name: "Emergency Response Time",
                    icon: PiClockCountdownFill,
                },
            ],
            services: [
                {
                    id: '0',
                    text: () => <Text><b>Highly Skilled Arborists:</b> Our team of certified arborists are unmatched.
                        Being locals, we understand Johnson City <a
                            href="https://www.landscapingveniceca.com/" target={'_blank'} rel={'noreferrer'}>tree
                            service</a> and landscaping needs.</Text>
                },
                {
                    id: '1',
                    text: () => <Text><b>Safe and Efficient Tree Services:</b> We take our work seriously and prioritize
                        safety. Our company adheres to strict safety protocols and uses advanced technology to ensure
                        everyone's safety throughout the <a
                            href="https://www.landscapingcompton.com/" target={'_blank'} rel={'noreferrer'}>tree
                            service</a> process.</Text>
                },
                {
                    id: '2',
                    text: () => <Text><b>Competitive Pricing:</b> We offer fair and competitive prices for our
                        top-notch&nbsp;<a href="https://www.maranalandscaper.com/" target={'_blank'}
                                          rel={'noreferrer'}>tree</a> care
                        services. Customers appreciate our pricing and continue to choose us for their Johnson City <a
                            href="https://www.landscapinglawndale.com/" target={'_blank'} rel={'noreferrer'}>tree
                            service</a> needs. Call for a quote today!</Text>
                },
                {
                    id: '3',
                    text: () => <Text><b>Comprehensive Tree Care:</b> Whether you need a tree removed, preserved or simply
                        managed, our team of Johnson City <a href="https://www.desplainestreeservice.com" target={"_blank"}
                                                             rel={'noreferrer'}>tree service</a> specialists offer a wide
                        range of services including fertilization, spraying, <Link
                            href={'/tree-pruning-johnson-city/'}>pruning</Link>, <Link
                            href={'/stump-grinding-johnson-city/'}>stump
                            grinding</Link>, storm damage and more. Looking for professional <a
                            href={"https://www.puyalluptreeservice.net/"} target={"_blank"} rel={"noreferrer"}>tree
                            service</a> in Johnson City?
                        We've got you covered.</Text>
                },
                {
                    id: '4',
                    text: () => <Text><b>Local Experience:</b> As Johnson City local tree experts, our experience and
                        reputation speak for itself! Our team of arborists understand the trees and landscape of the region
                        and have the right insights and experience to properly assess them.</Text>
                }
            ],
            content: [
                <>
                    <VStack spacing={4} align="left">
                        <Heading as="h2" size="lg">Johnson City Tree Service: Premier Tree Care in Johnson City,
                            TN</Heading>
                        <Text>Welcome to Johnson City Tree Service, your trusted partner for professional tree care in
                            Johnson City, Tennessee. Our team is skilled in providing various tree services tailored to the
                            needs of Johnson City residents and businesses. We value quality, safety, and the environment in
                            Johnson City. Our goal is to preserve and improve the natural beauty of the landscape.</Text>

                        <Heading as="h2" size="md">Johnson City's Expert Tree Service: Your Trusted Choice</Heading>
                        <Text>At Johnson City Tree Service, we take pride in providing a comprehensive range of tree care
                            solutions. Our tree experts work to make your trees look good, stay healthy, and keep your
                            property safe and attractive. We offer services like tree trimming, stump grinding, and tree
                            health maintenance to meet your needs.</Text>
                        <Text>Our team has the best tools and techniques, enabling us to deliver efficient and effective
                            tree services. If you have a dead tree or need tree trimming or stump grinding, our experts can
                            help. They have the skills to shape and improve the look of your trees and remove any leftover
                            stumps.</Text>
                        <Text>We are a tree company in Johnson City that knows how important keeping your trees healthy and
                            vibrant is. Our commitment extends beyond tree care—we strive to create a sustainable and
                            thriving environment. Rely on us for excellent tree services that enhance your landscape's
                            beauty and help your trees stay healthy and strong.</Text>
                        <Text>Choose Johnson City Tree Service for all your tree care needs. Let our skilled arborists bring
                            their expertise to your property, ensuring your trees and landscape thrive.</Text>
                        <Heading as="h3" size="sm">Versatile Tree Services Tailored to Your Needs</Heading>
                        <List spacing={2}>
                            <ListItem>
                                <Link href="/tree-trimming-johnson-city" fontWeight="bold">
                                    Tree Trimming:
                                </Link> Ensuring the health and beauty of your trees with precise tree trimming, tree
                                cutting, shrub trimming, and pruning services.
                            </ListItem>
                            <ListItem>
                                <Link href="/stump-grinding-johnson-city" fontWeight="bold">
                                    Stump Grinding:
                                </Link> We use stump grinders to ensure efficient stump removal to improve your landscape's
                                functionality and
                                appearance.
                            </ListItem>
                            <ListItem>
                                <Link href="/tree-planting-johnson-city" fontWeight="bold">
                                    Tree Planting and Transplanting:
                                </Link> Providing the best care during tree planting and transplanting for sustained tree
                                vitality.
                            </ListItem>
                            <ListItem>
                                <Link href="/tree-health-consulting-johnson-city" fontWeight="bold">
                                    Tree Health Consulting:
                                </Link> Expert advice from certified arborists for well-informed tree care decisions.
                            </ListItem>
                            <ListItem>
                                <Link href="/emergency-tree-services-johnson-city" fontWeight="bold">
                                    Emergency Tree Services:
                                </Link> Rapid response for urgent tree care needs in and around Johnson City and
                                Elizabethton TN.
                            </ListItem>
                            <ListItem>
                                <Link href="/arborist-consultations-johnson-city" fontWeight="bold">
                                    Arborist Consultations:
                                </Link> Professional insights on tree health, risk management, and sustainable tree
                                practices.
                            </ListItem>
                            <ListItem>
                                <Link href="/land-clearing-johnson-city" fontWeight="bold">
                                    Land Clearing:
                                </Link> Efficient and environmentally responsible land clearing services for various
                                projects.
                            </ListItem>
                            <ListItem>
                                <span style={{fontWeight: 'bold'}}>
                                    Tree Maintenance:
                                </span> A full spectrum of tree health services for ongoing tree care.
                            </ListItem>
                        </List>

                        <Heading as="h4" size="sm">Innovative Tree Care Techniques: Elevating Your Tree Services</Heading>
                        <Text>Johnson City Tree Service uses advanced techniques and technology to provide top-notch tree
                            care services. Our innovative approach sets us apart, enabling us to deliver more efficient,
                            safe, and eco-friendly services. Our team members stay current on tree care advancements to
                            provide the best possible care for your trees.</Text>
                        <Text>We use cutting-edge technology to enhance our tree care services' overall efficiency and
                            safety. By staying at the forefront of industry developments, we can provide solutions that meet
                            and exceed the highest standards. We use innovation to tailor our tree care to meet your trees'
                            needs, helping them stay healthy and live longer.</Text>
                        <Text>At Johnson City Tree Service, we know and understand the importance of environmentally
                            conscious
                            practices. We have proudly helped Johnson City and nearby areas by using eco-friendly methods
                            for sustainable tree care. We use technology and knowledge of trees to help the environment and
                            improve your trees. Our goal is to reduce impact and increase benefits.</Text>
                        <Text> Choose Johnson City Tree Service for forward-thinking tree care that leverages the latest
                            advancements in technology and techniques. Experience the difference that our innovative
                            approach can make in ensuring the health and vitality of your trees.</Text>

                        <Heading as="h5" size="sm">Safety and Sustainability Commitment: Our Priority</Heading>
                        <Text>Safety is a paramount concern at Johnson City Tree Service. Safety is our top priority for
                            everyone on our team, clients, and property.</Text>
                        <Text>We follow strict safety protocols during every tree service project, ensuring the safety of
                            everyone involved. We also dedicate ourselves to environmentally friendly practices, reducing
                            environmental impact and providing excellent tree care services.</Text>
                        <Text>We train our team to handle tree care safely and expertly. Safety is our top priority, so we
                            provide thorough training for all team members. We are a tree company with insurance and
                            licenses. We work safely to prevent accidents during tree services.</Text>
                        <Text>In addition to focusing on safety, our commitment to the environment guides our practices. We
                            use eco-friendly methods to improve tree health and reduce environmental impact. We aim to leave
                            your property thriving while maintaining a minimal carbon footprint.</Text>
                        <Text>At Johnson City Tree Service, safety and helping the environment go hand in hand. Our tree
                            care services meet high standards by following these principles. This gives you peace of mind
                            and ensures a healthier, more vibrant landscape.</Text>

                        <Heading as="h6" size="xs">Local Expertise: Deep Knowledge and Experience</Heading>
                        <Text>Our team in Johnson City has years of experience and knowledge about local trees, weather, and
                            environmental issues. We can provide personalized tree care services for the different types of
                            trees in Johnson City.</Text>
                        <Text>We are knowledgeable about the local environment. We can handle any issues that arise, such as
                            changes in weather, diseases, or specific needs of different species. We can use our knowledge
                            of the area to keep trees healthy year-round. You can do this by taking proactive and preventive
                            actions.</Text>
                        <Text>Johnson City Tree Service dedicates itself to providing excellent tree care, focusing on local
                            knowledge. We understand trees are unique, and our tailored solutions consider the individual
                            characteristics of Johnson City's tree population.</Text>
                        <Text>Where can I find shrub trimming near me? Pick Johnson City Tree Service for experienced
                            professionals who understand the local environment. We will give your trees the special care
                            they need, improving the health and beauty of Johnson City's natural scenery.</Text>
                        <Text>Why Choose Johnson City Tree Service? Choosing Johnson City Tree Service means opting for a
                            provider that values:</Text>
                        <List spacing={2}>
                            <ListItem>- Extensive expertise in a range of tree care services.</ListItem>
                            <ListItem>- Eco-friendly and sustainable practices.</ListItem>
                            <ListItem>- Professional, timely, and reliable service delivery.</ListItem>
                            <ListItem>- Customized solutions tailored to your specific tree care needs.</ListItem>
                            <ListItem>- Competitive pricing for high-quality tree services.</ListItem>
                        </List>
                        <Text>We are a leading tree service provider in Johnson City. We aim to ensure your ultimate
                            satisfaction and the health of your trees.</Text>

                        <Heading as="h2" size="md">Building Long-Term Relationships with Our Clients</Heading>
                        <Text>At Johnson City Tree Service, we believe in building lasting relationships with our clients.
                            We earn trust by providing excellent service and striving to surpass your expectations. We aim
                            to be your go-to tree service provider for all your future tree care needs. Contact Johnson City
                            Tree Service Today</Text>
                        <Text>Ready to experience the difference with Johnson City Tree Service? For top-tier tree care
                            services in Johnson City, TN, call us at 423-840-8746. Get a personalized quote and discover why
                            we are the go-to tree service provider in the region. Our team is eager to meet all your tree
                            care needs with expertise and dedication.</Text>
                    </VStack>
                    <Button as={'a'} href={'tel:4238408746'} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                            color={'white'} fontWeight={'bold'}
                            _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                            p={'16px 30px'} borderRadius={0} mt={'2rem'}
                            lineHeight={'26px'}
                            minH={'66px'}
                            width={'100%'}
                            maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for <Hide
                        below='md'>Immediate</Hide> Assistance</Button>
                </>
            ],
            additionalContent:
                <>
                    <Container maxW="container.xl">
                        <Box as="section" pt={10}>
                            <VStack spacing={4} align="left">
                                <Text>In the heart of Tennessee, where greenery flourishes, and landscapes are breathtaking,
                                    lies a
                                    service dedicated to the health and beauty of your trees—Johnson City Tree Service. We
                                    are
                                    more
                                    than just a service; we are stewards of the environment, caretakers of nature's giants,
                                    and
                                    your
                                    partners in maintaining a safe and stunning outdoor space.</Text>

                                <Heading as="h2" size="md">Our Commitment to Excellence</Heading>
                                <Text>Our mission at Johnson City Tree Service is to provide unparalleled tree care services
                                    to
                                    the
                                    Johnson City community. Our team of expert certified arborists and tree care
                                    professionals
                                    is
                                    equipped with the knowledge, tools, and passion to tackle any challenge your trees may
                                    present.
                                    From the majestic oak to the delicate cherry blossom, we understand each species' unique
                                    needs
                                    and intricacies, ensuring the best care and techniques are employed.</Text>

                                <Heading as="h3" size="sm">Comprehensive Tree Services</Heading>
                                <List spacing={2}>
                                    <ListItem>Tree Removal: Sometimes, a tree's life ends or becomes a hazard. Our tree
                                        removal services in Johnson City, TN are completed safely and efficiently, ensuring
                                        minimal impact on your property and the surrounding environment.</ListItem>
                                    <ListItem>Tree Pruning: Enhance the structure and health of your trees with our
                                        professional pruning services.</ListItem>
                                    <ListItem>Arborist Consultations: Consult with our certified arborists for personalized
                                        tree care recommendations.</ListItem>
                                    <ListItem>Stump Grinding and Removal: Leftover stumps can be an eyesore and a hazard.
                                        Our stump grinding services ensure the complete removal, leaving your property neat
                                        and hazard-free.</ListItem>
                                    <ListItem>Emergency Tree Services: Storms or unexpected events can turn trees into
                                        dangers. Our emergency services are swift and reliable, ensuring your property's
                                        safety and integrity.</ListItem>
                                    <ListItem>Tree Health and Maintenance: Beyond the basics, we offer shrub trimming
                                        services, deep-root fertilization, disease management, and preventive care to ensure
                                        your trees live long, healthy lives.</ListItem>
                                </List>

                                <Heading as="h3" size="sm">Our Process - Ensuring Healthy Trees Every Step of the
                                    Way</Heading>
                                <List spacing={2}>
                                    <ListItem>Initial Assessment: We begin by assessing your trees' health and specific care
                                        needs.</ListItem>
                                    <ListItem>Customized Care Plan: Based on our thorough assessment, we create a tailored
                                        care
                                        plan
                                        designed to address your trees' unique requirements.</ListItem>
                                    <ListItem>Expert Execution: Our certified arborists implement the care plan with
                                        precision
                                        and
                                        expertise, ensuring optimal results.</ListItem>
                                    <ListItem>Quality Assurance: We conduct post-service evaluations to ensure our work
                                        meets
                                        the
                                        best quality and satisfaction standards.</ListItem>
                                    <ListItem>Follow-Up Care: We provide ongoing guidance and support to help you promote
                                        health
                                        and
                                        ensure the beauty of your trees for years to come.</ListItem>
                                </List>

                                <Text>In a market filled with tree care options, Johnson City Tree Service stands out for
                                    several vital reasons that resonate with our customers in the Johnson City area:</Text>
                                <List spacing={2}>
                                    <ListItem>Local Expertise: Our deep understanding of the local ecosystem enables us to
                                        provide
                                        tailored tree care solutions.</ListItem>
                                    <ListItem>Certified Arborists: Our certified arborists are committed to promoting the
                                        health
                                        and
                                        maintaining the beauty of your trees.</ListItem>
                                    <ListItem>Customer-Centric Approach: Your satisfaction is our priority. We listen to
                                        your
                                        tree
                                        care needs and tailor our services accordingly.</ListItem>
                                    <ListItem>Environmentally Conscious: We prioritize eco-friendly practices in our tree
                                        care
                                        services, promoting sustainability.</ListItem>
                                    <ListItem>Comprehensive Services: We cover all your tree care needs, from initial
                                        assessment
                                        to
                                        post-service guidance.</ListItem>
                                </List>
                            </VStack>
                        </Box>

                        <Box as="section" pt={10}>
                            <Heading as="h2" size="lg" mb={4}>
                                Frequently Asked Questions (FAQs) About Tree Care
                            </Heading>
                            <Grid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={6}>
                                {faqs.map(({question, answer}, index) => (
                                    <Box
                                        p={5}
                                        borderWidth="1px"
                                        borderRadius="lg"
                                        key={index}
                                    >
                                        <Heading as={"h3"} fontSize="xl">{question}</Heading>
                                        <Text mt={4}>{answer}</Text>
                                    </Box>
                                ))}
                            </Grid>
                        </Box>

                        <Box as="section" pt={10}>
                            <Heading as="h2" size="lg" mb={4}>
                                Begin Your Journey to Healthy Trees Today
                            </Heading>
                            <Text mb={4}>
                                Ready to give your trees the care they deserve? Contact Johnson City Tree Service today to
                                schedule a
                                consultation and take the first step toward vibrant, healthy trees that enhance the beauty
                                and value of
                                your property.
                            </Text>
                            <Button colorScheme="green" size="md" mb={4} as={'a'} href={telLink}>
                                Contact Us Now
                            </Button>
                        </Box>
                    </Container>
                </>,
            contactSection: <>
                <Container maxW="container.xl">
                    <Box as="section">
                        <Heading as="h2" size="lg" mb={4}>
                            Contact Us and Consultation
                        </Heading>
                        <Text mb={4}>
                            Your trees and landscape are important to you, and they are important to us too. Whether you
                            have a specific need, a question about our services, or just want to chat about the best way to
                            care for your trees, we're here for you. Contact us today to schedule a consultation or to learn
                            more about how we can help you enhance the beauty and safety of your property.
                        </Text>
                        <Text mb={4}>
                            Our consultation process is designed to understand your needs and provide the best solutions.
                            During a consultation, one of our experts will visit your property, assess the situation, and
                            discuss your options. We believe in transparent communication and will provide you with detailed
                            information and recommendations.
                        </Text>
                        <Button colorScheme="green" size="md" mb={4} as={'a'} href={telLink}>
                            Schedule a Consultation
                        </Button>
                        <Text mb={4}>
                            Follow us on <Link
                            href={'https://www.facebook.com/people/Johnson-City-Tree-Service/61554736207234'}>social
                            media</Link> and stay updated with seasonal tips, our latest projects, and
                            community involvement. We love sharing our passion for trees and the environment and staying
                            connected with our clients and community.
                        </Text>
                    </Box>
                </Container>
            </>,
            footerHeading:
                'Contact Us for Tree Service in Johnson City, TN',
            footerText:
                () => <>For top-notch tree services in Johnson City TN, reach out to Johnson City Tree Service
                    today. Call or fill out our <Link href={'/contact'}>online contact form</Link> for inquiries. We are
                    your
                    go-to experts for all tree care needs in Johnson City!</>,
            footerText2:
                'Expert Tree Service in Johnson City TN - Our Commitment to Your Trees\' Health and Beauty',
            servicesHeader:
                'Explore Our Tree Services in Johnson City, TN',
            servicesSubtitle:
                () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Discover our <Link
                    href={'/services'}>comprehensive tree services</Link> in Johnson City, TN. From emergency tree removal
                    to
                    routine tree health maintenance, Johnson City Tree Service is here for you.</Text>,
            featuredServices:
                [
                    {
                        name: 'Tree Trimming',
                        description: 'We offer complete tree trimming and pruning services to maintain the health and aesthetics of your trees, contributing to the overall beauty of your outdoor spaces.',
                        icon: BiCut,
                        path: '/tree-trimming-johnson-city',
                        linkTitle: 'Tree Trimming'
                    },
                    {
                        name: 'Tree Removal',
                        description: 'Safe and efficient tree removal services for hazardous or unwanted trees, ensuring your  property’s safety and aesthetic appeal.',
                        icon: GiChoppedSkull,
                        path: '/tree-removal-johnson-city',
                        linkTitle: 'Tree Removal'
                    },
                    {
                        name: 'Stump Grinding',
                        description: 'We specialize in stump grinding and removal, aiming to safely and efficiently clear unwanted stumps from your yard, improving the usability of your landscape.',
                        icon: GiTreeRoots,
                        path: '/stump-grinding-johnson-city',
                        linkTitle: 'Stump Grinding'
                    },
                    {
                        name: 'Tree Planting and Transplanting',
                        description: 'Our tree planting and transplanting service ensures optimal care and handling of your trees during transit, ideal for those wishing to relocate trees without causing them distress.',
                        icon: GiTreeDoor,
                        path: '/tree-planting-johnson-city',
                        linkTitle: 'Tree Planting and Transplanting'
                    },
                    {
                        name: 'Tree Health Consulting',
                        description: 'Need professional advice on your trees? Our certified arborist consulting service can equip you with the necessary insight and recommendations to make informed decisions about your tree care.',
                        icon: FaConciergeBell,
                        path: '/tree-health-consulting-johnson-city',
                        linkTitle: 'Tree Health Consulting'
                    },
                    {
                        name: 'Emergency Tree Services',
                        description: 'Offering prompt and reliable emergency tree services, we are prepared to address urgent tree care needs caused by storms, damage, or other unforeseen events.',
                        icon: MdOutlineEmergency,
                        path: '/emergency-tree-services-johnson-city',
                        linkTitle: 'Emergency Tree Services'
                    },
                    {
                        name: 'Arborist Consultations',
                        description: 'Gain expert insights and solutions for your trees with our professional arborist consultations, focusing on tree health, risk assessment, and sustainable management.',
                        icon: MdOutlineNaturePeople,
                        path: '/arborist-consultations-johnson-city',
                        linkTitle: 'Arborist Consultations'
                    },
                    {
                        name: 'Land Clearing',
                        description: 'Our comprehensive land clearing services prepare your property for construction, landscaping, or agricultural projects, ensuring an efficient and eco-friendly process.',
                        icon: FaRegHandScissors,
                        path: '/land-clearing-johnson-city',
                        linkTitle: 'Land Clearing'
                    },
                ]
        },
        careers: {
            "url":
                "https://www.johnsoncitytreeservice.com/careers/",
            "metaData":
                {
                    "title":
                        "Career Opportunities at Johnson City Tree Service in TN",
                    "description":
                        "Join our team of tree care professionals at Johnson City Tree Service in TN. Explore career opportunities and grow with us. Apply now!"
                }
            ,
            "h1":
                "We're Hiring - Become a Part of Johnson City Tree Service",
            "h2":
                [
                    "Why Join Johnson City Tree Service?",
                    "Current Openings for Tree Care Specialists",
                    "Our Commitment to Employee Development and Satisfaction"
                ],
            "headingText":
                {
                    "whyJoin":
                        "Discover a workplace where your passion for trees and the environment is valued. Join a team committed to providing exceptional tree care services in Johnson City, TN.",
                    "openings":
                        "Browse our current job openings for arborists, tree climbers, and grounds maintenance staff. Find a role that aligns with your skills and passion for tree care.",
                    "commitment":
                        "At Johnson City Tree Service, we believe in investing in our team's expertise and providing a supportive, dynamic work environment. Your growth in the field of arboriculture is integral to our success."
                }
            ,
            "content":
                [
                    <Text>Welcome to the Careers section at <Link href={'/'}>Johnson City Tree Service</Link>, where your
                        career
                        in arboriculture and tree care can flourish. We're a family of professionals dedicated to the
                        highest
                        standards in tree services. Join us in making a positive impact in Johnson City, TN.</Text>,
                    <Text>At <Link href={'/'}>Johnson City Tree Service</Link>, we understand that our strength comes from
                        our
                        people. We are looking for dedicated individuals who share our commitment to exceptional tree care.
                        Whether you're an experienced arborist or eager to learn, we offer a workplace where your skills are
                        appreciated and nurtured.</Text>,
                    <Text>Our employees enjoy a culture that promotes personal and professional growth. We provide
                        comprehensive
                        training, competitive compensation, and the opportunity to work with advanced tree care equipment.
                        Our
                        goal is to create a safe, rewarding, and enjoyable workplace for everyone on our team.</Text>,
                    <Text>As a key part of Johnson City's community, we take pride in serving our neighbors with integrity
                        and
                        professionalism. Our commitment to service excellence extends to our team members, whom we support
                        with
                        a positive work environment, respectful teamwork, and opportunities for career advancement.</Text>,
                    <Text>Are you ready to join a leading tree service provider in Johnson City, TN? Check out our current
                        job
                        openings and find where you fit in our growing team. From skilled tree climbers to customer service
                        representatives, there's a place for you at Johnson City Tree Service.</Text>,
                    <Text><a href='https://www.johnsoncitytreeservice.com/careers/'>Discover your next career
                        opportunity</a> with us. Be a part of a team that's dedicated to preserving and enhancing the urban
                        canopy. At <Link href={'/'}>Johnson City Tree Service</Link>, we're more than just a company; we're
                        a
                        community. Apply today and grow your career in tree care.</Text>,
                ]
        }
        ,
        about: {
            url: "https://www.johnsoncitytreeservice.com/about/",
            metaData:
                {
                    title: "Tree Service in Johnson City, TN - Call Us",
                    description:
                        "Need tree care in Johnson City, TN? Trust our expert team for pruning, removal, and more. Call (423) 840-8746 for reliable service.",
                }
            ,
            h1: "About Us | Tree Service in Johnson City TN",
            h2:
                [
                    "Emergency Tree Services: Available 24/7 for urgent tree care and removal needs.",
                    "Experienced Arborists: Our team of certified arborists is trained and equipped to provide expert tree care.",
                    "Comprehensive Tree Care: Offering a wide range of services from tree health assessments to stump grinding.",
                    "Eco-Friendly Practices: We prioritize the health of your trees and the environment in every service we provide.",
                    "Competitive Pricing: Our services are affordably priced, ensuring quality tree care is accessible to all."
                ],
            headingText:
                {
                    commitment: "Dedicated to delivering exceptional services in Johnson City Tree Service today, ensuring the health and beauty of Johnson City's urban forest.",
                    team:
                        "Our team is composed of passionate arborists and tree care professionals, committed to excellence in every project.",
                    services:
                        "We offer a full spectrum of tree care services. From routine pruning and maintenance to emergency tree removals and disease management, our services cater to all your tree care needs. We use the latest techniques and equipment to ensure the health and safety of your trees and property. Whether it's a residential garden or a commercial landscape, our team provides professional, efficient, and eco-friendly services."
                }
            ,
            services: [
                {
                    id: "0",
                    title: "Tree Pruning & Trimming",
                    href: "/tree-pruning-johnson-city",
                    icon: AiOutlineScissor,
                    description: (
                        <Text>
                            Precision pruning and trimming to maintain the health and appearance of your trees, performed by
                            our skilled arborists.
                        </Text>
                    ),
                },
                {
                    id: "1",
                    title: "Tree Removal",
                    href: "/tree-removal-johnson-city/",
                    icon: GiChoppedSkull,
                    description: (
                        <Text>
                            We do an excellent job as the premiere tree company that is always ready to offer unmatched tree
                            care
                            that includes safe and efficient tree removal services for hazardous or unwanted trees, ensuring
                            your property’s safety and aesthetic appeal.
                        </Text>
                    ),
                },
                {
                    id: "2",
                    title: "Stump Grinding & Removal",
                    href: "/stump-grinding-johnson-city/",
                    icon: GiStumpRegrowth,
                    description: (
                        <Text>
                            Comprehensive stump grinding and stump removal services with the latest technology in stump
                            grinders to clear your landscape and prepare it
                            for new
                            growth or landscaping projects.
                        </Text>
                    ),
                },
                {
                    id: "3",
                    title: "Emergency Tree Services",
                    href: "/emergency-tree-services-johnson-city/",
                    icon: MdEmergencyShare,
                    description: (
                        <Text>
                            Prompt and reliable emergency tree services for storm damage, fallen trees, or urgent tree care
                            needs.
                        </Text>
                    ),
                },
                {
                    id: "4",
                    title: "Tree Health Assessments",
                    href: "/tree-health-assessments/",
                    icon: MdHealthAndSafety,
                    description: (
                        <Text>
                            Expert assessments from our licensed and insured tree care professionals to diagnose and treat
                            tree diseases and pests, ensuring the longevity and
                            vitality of your trees.
                        </Text>
                    ),
                },
                {
                    id: "5",
                    title: "Arborist Consultations",
                    href: "/arborist-consultations-johnson-city/",
                    icon: BiConversation,
                    description: (
                        <Text>
                            Professional arborist consultations to advise on tree care, planting, and maintenance for
                            healthy and sustainable landscapes.
                        </Text>
                    ),
                },
                {
                    id: "6",
                    title: "Land Clearing & Lot Preparation",
                    href: "/land-clearing-johnson-city/",
                    icon: GiLandMine,
                    description: (
                        <Text>
                            Efficient land clearing services for construction projects, landscaping, and property
                            development, with a focus on environmental responsibility.
                        </Text>
                    ),
                },
            ],
        },
        testimonials: {
            h1: 'Testimonials',
            heading:
                'Find out why our customers love us – from tree trimming to emergency tree removal, we\'re the top-rated choice for all your tree service needs',
            testimonials:
                [
                    {
                        avatarUrl: 'https://i.pravatar.cc/150?u=a042581f4e29026704f',
                        name: 'Joanna E.',
                        logo: undefined,
                        title: 'Johnson City, TN',
                        quote:
                            '"Last autumn, we were confronted with a considerable obstacle when a colossal tree in our backyard was hit by lightning. We were anxious it would cause damage to our house. Johnson City Tree Service promptly arrived with their crew, swiftly evaluated the situation, and securely removed the tree. Their expertise, professionalism, and effectiveness were beyond comparison."',
                    },
                    {
                        avatarUrl: 'https://i.pravatar.cc/150?u=a0425d',
                        name: 'Michael W.',
                        logo: undefined,
                        title: 'Johnson City, TN',
                        quote:
                            '"I have relied on Johnson City Tree Service numerous times for tree pruning. Every instance they have impressed me with their knowledge, professionalism, and the quality of their work. They leave my trees looking vibrant and impeccably manicured. Furthermore, their post-work cleanup is always flawless. For any tree care necessities in Johnson City, I strongly recommend them."',
                    },
                    {
                        avatarUrl: 'https://i.pravatar.cc/150?u=a0804d',
                        name: 'Rob B.',
                        logo: undefined,
                        title: 'Johnson City, TN',
                        quote:
                            '"I recently used Johnson City Tree Service for a major land clearing project on my property. The level of professionalism and attention to detail was exceptional. They completed the job efficiently, and their concern for environmental impact was evident. They went above and beyond to ensure the land was perfectly prepared for my next project. Highly recommended!"',
                    },
                ]
        }
        ,
        services: {
            metaData: {
                title: 'Top-notch Tree Care in Johnson City | Call Us: (423) 840-8746',
                description:
                    'Count on our team for top-notch tree care services in Johnson City, TN. From pruning to removal, we have you covered. Contact us today!',
            }
            ,
            h1: 'Your Local Experts for Exceptional Tree Services in Johnson City, TN',
            h2:
                'The Tree Care Services We Provide',
            subtext:
                'At Johnson City Tree Service, our mission is to provide a variety of tree care services that focus on your landscape’s health and aesthetics at competitive rates.',
            footerHeading:
                'Ready to Elevate Your Landscape?',
            footerText2:
                () => <>Reach out to us at <Link href={telLink}>{phoneNumberWithDashes}</Link> or
                    use our
                    online contact form for a free quote. Experience the Johnson City Tree Service difference today!</>,
            footerText:
                () => <>Remember, at Johnson City Tree Service, your trees are our primary responsibility. For
                    superior tree care in Johnson City, TN, we're the team to trust. We're just a call away!</>,
            content:
                <Box>
                    <Stack spacing={5}>
                        <Heading as="h2" size="xl">Johnson City Tree Service: Expert Tree Care in Johnson City, TN</Heading>
                        <Text>At Johnson City Tree Service, we're dedicated to offering superior tree care and maintenance
                            services in Johnson City, TN. Whether you need routine pruning, emergency tree removal, or
                            specialized arborist advice, our team of certified professionals is ready to provide safe,
                            efficient, and environmentally responsible services. We take pride in our commitment to customer
                            satisfaction and our expertise in handling a wide range of tree-related needs.</Text>

                        <Heading as="h3" size="lg">
                            Why Choose Johnson City Tree Service?
                        </Heading>
                        <List spacing={3}>
                            <ListItem>
                                <Text>
                                    <b>Experienced Arborists:</b> Our certified arborists bring
                                    extensive knowledge and skill to every project, ensuring the
                                    highest standards of tree care.
                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text>
                                    <b>Advanced Tree Care Techniques:</b> We use modern,
                                    eco-friendly methods and equipment to provide comprehensive tree
                                    care that prioritizes the health of your trees and the
                                    environment.
                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text>
                                    <b>24/7 Emergency Services:</b> Understanding that tree
                                    emergencies can occur at any time, we offer around-the-clock
                                    services to address urgent tree care needs.
                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text>
                                    <b>Competitive Pricing:</b> We believe in offering high-quality
                                    services at fair and transparent prices, delivering value to
                                    every client.
                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text>
                                    <b>Local Expertise:</b> Familiar with the specific tree species
                                    and challenges in Johnson City, TN, we provide tailored care for
                                    optimal tree health and aesthetics.
                                </Text>
                            </ListItem>
                        </List>

                        <Heading as="h3" size="lg">
                            Comprehensive Tree Care & Maintenance Services
                        </Heading>
                        <List spacing={3}>
                            <ListItem>
                                <Text>
                                    <b>Tree Trimming and Pruning:</b> Our precise trimming and
                                    pruning services enhance the health and beauty of your trees,
                                    contributing to a safer and more appealing landscape.
                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text>
                                    <b>Emergency Tree Services:</b> Responding swiftly to emergency
                                    situations, we handle storm-damaged trees, fallen limbs, and
                                    other urgent scenarios with expertise and care.
                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text>
                                    <b>Stump Grinding and Removal:</b> We provide effective stump
                                    grinding services to clear your property and facilitate new
                                    landscaping opportunities.
                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text>
                                    <b>Arborist Consultations:</b> Benefit from our arborist's
                                    expert insights on tree health, risk assessment, and proper tree
                                    care strategies.
                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text>
                                    <b>Land Clearing:</b> Our land clearing services prepare your
                                    property for new construction, landscaping, or agricultural use,
                                    ensuring an efficient and eco-friendly process.
                                </Text>
                            </ListItem>
                        </List>

                        <Heading as="h3" size="lg">
                            Tailored Solutions for Your Trees
                        </Heading>
                        <Text>
                            We recognize that each tree and property has unique needs. Our
                            customized approach ensures that every aspect of your tree care is
                            handled with attention to detail and tailored to your specific
                            requirements.
                        </Text>

                        <Heading as="h3" size="lg">
                            Commitment to Safety and Environmental Care
                        </Heading>
                        <Text>
                            At Johnson City Tree Service, your safety and the health of your
                            trees are our top priorities. We're dedicated to providing services
                            that not only meet but exceed industry standards for safety and
                            environmental responsibility.
                        </Text>

                        <Heading as="h3" size="lg">
                            Contact Us for Professional Tree Care
                        </Heading>
                        <Text>
                            For expert tree care and maintenance services in Johnson City, TN,
                            trust Johnson City Tree Service. Contact us today for professional,
                            reliable, and eco-conscious tree care solutions.
                        </Text>
                    </Stack>
                </Box>,
            services:
                [
                    {
                        id: "0",
                        text: () => (
                            <Text>
                                <b>
                                    <Link href={"/tree-pruning-johnson-city"}>Tree Pruning</Link> and{" "}
                                    <Link href={"/tree-trimming-johnson-city"}>Trimming:</Link>
                                </b>{" "}
                                We offer top-tier care in pruning and trimming, removing unnecessary
                                branches and addressing foliage for healthier and aesthetically
                                appealing trees.
                            </Text>
                        ),
                    },
                    {
                        id: "1",
                        text: () => (
                            <Text>
                                <b>
                                    <Link href={"/stump-grinding-johnson-city/"}>Stump Grinding</Link>:
                                </b>{" "}
                                Our stump grinding service helps eliminate unsightly or hazardous
                                tree stumps from your landscape, ensuring a neat and safe
                                environment.
                            </Text>
                        ),
                    },
                    {
                        id: "2",
                        text: () => (
                            <Text>
                                <b>
                                    <Link href={"/tree-removal-johnson-city/"}>Tree Removal</Link>:
                                </b>{" "}
                                For trees posing a threat to your property due to structural issues
                                or decay, our team can safely remove them.
                            </Text>
                        ),
                    },
                    {
                        id: "3",
                        text: () => (
                            <Text>
                                <b>
                                    <Link href={"/tree-health-consulting"}>
                                        Tree Health Consulting
                                    </Link>
                                    :
                                </b>{" "}
                                Our expert arborists are ready to assess your trees' health and
                                offer specific treatments when needed.
                            </Text>
                        ),
                    },
                ],
            whyh2:
                "Why Choose Johnson City Tree Service?",
            whySubtext:
                () => (
                    <>
                        Our experts at Johnson City Tree Service have been providing
                        unparalleled professional tree care services in the community for over
                        30 years. We work in sync with your unique needs to ensure proper
                        handling of your trees and property. Reach out to us at{" "}
                        <Link href={telLink} textDecoration={"underline"}>
                            {phoneNumberWithDashes}
                        </Link>
                        .
                    </>
                ),
            whyServices:
                [
                    {
                        id: "0",
                        text: () => (
                            <Text>
                                <b>Experienced Professionals:</b> Our dedicated team consists of
                                trained professionals with years of expertise in tree care, ready to
                                handle any project, big or small.
                            </Text>
                        ),
                    },
                    {
                        id: "1",
                        text: () => (
                            <Text>
                                <b>Unmatched Service:</b> We prioritize our customers and their
                                needs, ensuring availability throughout the project and working
                                diligently until their satisfaction.
                            </Text>
                        ),
                    },
                    {
                        id: "2",
                        text: () => (
                            <Text>
                                <b>Reliable and Efficient:</b> We have built a strong reputation
                                through years of consistent, top-notch service to our community.
                            </Text>
                        ),
                    },
                    {
                        id: "3",
                        text: () => (
                            <Text>
                                <b>Local Experts:</b> Our team's deep understanding of Johnson
                                City's local botany, topography, and landscape provides our
                                customers with a level of expertise that is unrivaled.
                            </Text>
                        ),
                    },
                ],
        }
        ,
        contact: {
            url: "https://www.johnsoncitytreeservice.com/contact/",
            metaData:
                {
                    title: "Johnson City Tree Service | Expert Tree Care Services",
                    description:
                        "Connect with us for professional tree care in Johnson City, TN. We maintain the health and beauty of your trees with expert services.",
                }
            ,
            h1: "Contact Us",
            subtitle:
                "Thank you for choosing Johnson City Tree Service. We are a committed tree service company operating in Johnson City, Tennessee, and nearby areas, delivering reliable tree care services to our valued customers. Our professional and friendly team is eager to assist with all your tree care requirements.",
            sectionHeading:
                "Why Choose Johnson City Tree Service?",
            signUpDescription:
                "If you wish to reach out to us online, kindly fill out the form below. We will respond as promptly as possible.",
            feedbackText:
                "Your feedback is valuable to us. If you have any questions or concerns, we would be delighted to hear from you. Ensuring your satisfaction is our highest priority.",
            closingText:
                () => (
                    <>Providing trusted tree care services in Johnson City, TN.</>
                ),
            valueProps:
                [
                    {
                        title: "Certified Arborists:",
                        description:
                            "Our team of certified arborists possesses years of experience and knowledge about the best tree care techniques. We take pride in delivering exceptional service to ensure the well-being and aesthetics of your trees.",
                    },
                    {
                        title: "Comprehensive Tree Services:",
                        description:
                            "From regular tree maintenance to removal and stump grinding, we provide an extensive range of services. Our skilled team is equipped with the right tools to cater to all your tree care necessities.",
                    },
                    {
                        title: "Modern Equipment:",
                        description:
                            "We employ top-quality, up-to-date equipment to ensure efficient and secure tree care services. Our equipment allows us to handle trees of any size and condition.",
                    },
                    {
                        title: "Environmentally Aware:",
                        description:
                            "We understand the ecological significance of trees and aim to deliver sustainable services. We follow best practices to reduce environmental impact during tree removal or any other services.",
                    },
                    {
                        title: "Customer Satisfaction:",
                        description:
                            "At Johnson City Tree Service, our aim is to exceed your expectations. We are dedicated to providing superior customer service and ensuring a smooth experience from start to finish.",
                    },
                ],
        }
        ,
        blog: {
            url: "https://www.johnsoncitytreeservice.com/blog/",
            metaData:
                {
                    title: "Professional & Affordable Tree Services | Blog",
                    description:
                        "Professional tree care services in Johnson City, TN. Trimming, removal, and expert advice. Contact us for affordable and top-quality tree care solutions.",
                }
            ,
            h1: "Johnson City Blog: Our Articles and Tips",
            h2:
                "Our Blog",
            heading:
                "At Johnson City Tree Service, we strive to provide our clients with the best information guiding them to informed decisions to maintain their landscapes long after our job is done. Of course, we are always just a phone call away so feel free to contact us at any time. We are here for you!",
            posts:
                [
                    {
                        id: "1",
                        title: "The Ultimate Guide to Tree Pruning in Johnson City",
                        metaTitle:
                            "Your Comprehensive Guide to Tree Pruning in Johnson City | Call Us: (423) 840-8746 Blog",
                        excerpt:
                            "Are you looking to understand tree pruning and its importance better? This article shares the ultimate guide for Johnson City homeowners.",
                        slug: "ultimate-guide-to-tree-pruning-in-johnson-city",
                        tags: ["tree pruning", "guide", "Johnson City"],
                        metaDescription:
                            "Curious about tree pruning in Johnson City? Discover our professional guide to keep your trees in optimal health and enhance their aesthetic appeal.",
                    },
                    {
                        id: "2",
                        title: "The Essential Steps to Safe Tree Removal in Johnson City",
                        metaTitle:
                            "How to Safely Remove a Tree in Johnson City | Call Us: (423) 840-8746 Blog",
                        excerpt:
                            "Removing a tree can sometimes be a necessity. This article provides essential steps to safe and efficient tree removal for Johnson City homeowners.",
                        slug: "essential-steps-to-safe-tree-removal-in-johnson-city",
                        tags: ["tree removal", "safety", "Johnson City"],
                        metaDescription:
                            "Learn the vital steps to safe and efficient tree removal in Johnson City with our professional guide.",
                    },
                    {
                        id: "3",
                        title: "Benefits of Professional Tree Trimming in Johnson City",
                        metaTitle:
                            "Why You Should Consider Professional Tree Trimming in Johnson City | Call Us: (423) 840-8746 Blog",
                        excerpt:
                            "Considering tree trimming for your property? Discover the benefits of professional tree trimming services in Johnson City with our latest blog post.",
                        slug: "benefits-of-professional-tree-trimming-in-johnson-city",
                        tags: ["tree trimming", "benefits", "Johnson City"],
                        metaDescription:
                            "Understand the significant benefits of professional tree trimming in Johnson City with our insightful blog post.",
                    },
                    {
                        id: "4",
                        title: "Understanding the Process of Stump Grinding in Johnson City",
                        metaTitle:
                            "Your Guide to Stump Grinding in Johnson City | Call Us: (423) 840-8746 Blog",
                        excerpt:
                            "Have an unsightly stump in your yard? Learn about the process of stump grinding in Johnson City, TN, and why it can be crucial for your property.",
                        slug: "understanding-stump-grinding-in-johnson-city",
                        tags: ["stump grinding", "process", "Johnson City"],
                        metaDescription:
                            "Discover the process and benefits of stump grinding in Johnson City with our comprehensive blog post.",
                    },
                ],
        }
        ,
        treePruning: {
            url: "https://www.johnsoncitytreeservice.com/tree-pruning",
            metaData:
                {
                    title:
                        "Professional Tree Pruning in Johnson City, TN",
                    description:
                        "Highlight the best in your trees with professional tree pruning services in Johnson City, TN. Contact Johnson City Tree Service today!",
                }
            ,
            h1: "Tree Pruning Services in Johnson City, TN",
            subtitle:
                "Trees are more than just a landscape feature. They are living, breathing entities that require proper care and maintenance to stay healthy and robust. One of the most effective ways to ensure the health and beauty of your trees is through regular pruning. At Johnson City Tree Service, we offer professional tree pruning services designed to enhance the health, aesthetic, and safety of your trees.",
            imgAlt:
                "tree pruning johnson city tn",
            body:
                (
                    <Box>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h3"}>Our Tree Pruning Services</Heading>
                            <Text>
                                At Johnson City Tree Service, we are committed to delivering
                                top-tier tree pruning services at competitive prices. Let's take a
                                look at what we bring to the table:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Health Pruning:</b> Our professionals identify and remove
                                    branches that are dead, diseased, or infested by pests, halting
                                    the spread of harmful agents and bolstering overall tree health.
                                </ListItem>
                                <ListItem>
                                    <b>Structural Pruning:</b> By targeting problems at the root and
                                    the base, we prune your trees to foster a robust structure that
                                    mitigates storm damage and reduces the likelihood of branch
                                    failure.
                                </ListItem>
                                <ListItem>
                                    <b>Aesthetic Pruning:</b> Trees don't just support the environment
                                    and human health – they're a treat for the eyes too. We shape and
                                    trim your trees to enhance their inherent beauty and augment your
                                    property's overall aesthetic appeal.
                                </ListItem>
                                <ListItem>
                                    <b>Safety Pruning:</b> Our team removes branches that could
                                    potentially harm your property, ensuring your landscape remains a
                                    safe haven.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h3"}>
                                Why Choose Johnson City Tree Service for Your Tree Pruning Needs?
                            </Heading>
                            <Text>
                                With a deep-rooted understanding of the Johnson City area and a
                                proven track record in tree pruning, here’s why we are your best
                                choice:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Expert Arborists:</b> Our team of highly skilled arborists has
                                    the knowledge and expertise necessary to prune your trees
                                    effectively and safely.
                                </ListItem>
                                <ListItem>
                                    <b>Quality Service:</b> We prioritize delivering high-quality
                                    service, and our customer reviews and impressive track record are
                                    a testament to this commitment!
                                </ListItem>
                                <ListItem>
                                    <b>Local Experts:</b> Our team members are intimately familiar
                                    with the local region's botany, shrubs, and landscapes, enabling
                                    us to provide specialized and targeted approaches that you won't
                                    find elsewhere.
                                </ListItem>
                                <ListItem>
                                    <b>Customer Satisfaction:</b> We're dedicated to ensuring our
                                    customers are happy and satisfied, and we commit to making the
                                    process as seamless as possible.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h3"}>
                                Premium Tree Pruning Services in Johnson City, TN
                            </Heading>
                            <Text>
                                Contact us today at{" "}
                                <Link href={telLink}>{phoneNumberWithDashes}</Link> to schedule your
                                tree pruning service. Experience the Johnson City Tree Service
                                difference!
                            </Text>
                        </Stack>
                    </Box>
                ),
        }
        ,
        treeRemoval: {
            url: "https://www.johnsoncitytreeservice.com/tree-removal/",
            metaData:
                {
                    title: "Tree Removal Johnson City TN | Call Us: (423) 840-8746",
                    description:
                        "Johnson City Tree Service offers expert tree removal services in Johnson City, TN. Contact us for reliable and efficient tree removal",
                }
            ,
            h1: `Expert Tree Removal Johnson City TN`,
            subtitle:
                "When a tree becomes a hazard or is irreversibly damaged, it may be time for its removal. At Johnson City Tree Service, we specialize in safe, efficient tree removal services, providing peace of mind to homeowners and businesses throughout Johnson City, TN.",
            imgAlt:
                "tree removal johnson city tn",
            body:
                (
                    <Container maxW="container.xl" py={10}>
                        <Box py={5}>
                            <Heading as="h2" size="xl" mb={4}>
                                Expert Tree Removal Services in Johnson City, TN
                            </Heading>
                            <Text fontSize="lg" mb={4}>
                                In the heart of Tennessee, Johnson City stands as a beacon of community and natural beauty.
                                However, the health and management of the trees contributing to this beauty require careful
                                attention. Johnson City Tree Service is at the forefront of providing specialized tree
                                removal
                                services to maintain your property's safety, health, and aesthetics. Whether dealing with
                                dangerous, dead, diseased, or obstructive trees, understanding the value and process of
                                professional tree removal is crucial for every property owner.
                            </Text>
                            <Text fontSize="lg" mb={4}>
                                At Johnson City Nexus Tree Service, we specialize in safe and efficient tree removal
                                services customized to meet the needs of both residential and commercial properties. Our
                                certified arborists are equipped with state-of-the-art equipment to handle any tree removal
                                task, big or small, with the utmost precision and care.
                            </Text>
                            <Text mb={4}>
                                Trees add immeasurable value to our environment, offering beauty and shade and even
                                improving
                                air quality. However, when trees become a hazard, their removal becomes necessary to protect
                                property and human safety. The reasons for tree removal are varied, including:
                            </Text>
                            <Heading as="h2" size="lg" mb={3}>
                                Why Choose Us?
                            </Heading>
                            <Text mb={4}>
                                Choose Johnson City Nexus Tree Service for reliable tree removal. Our team is experienced,
                                certified, and insured, ensuring that every project is completed safely and effectively. We
                                understand the importance of maintaining a safe environment and minimizing impact to your
                                property during the tree removal process.
                            </Text>
                            <Heading as="h2" size="lg" mb={3}>
                                Our Tree Removal Services
                            </Heading>
                            <List spacing={3} mb={4} p={0}>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Residential Tree Removal: We provide careful and efficient removal of trees from
                                    residential properties, ensuring safety and minimal disruption to your landscape.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Commercial Tree Removal: Our commercial services are designed to handle large-scale
                                    removals with precision. Whether it’s routine clearance or emergency response, we are
                                    equipped to manage the job.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Emergency Tree Removal: Available 24/7, our emergency services are ready to respond to
                                    storm damage or other urgent situations where trees pose a risk to safety.
                                </ListItem>
                            </List>

                            <Heading as="h2" size="lg" mb={3}>
                                Benefits of Professional Tree Removal
                            </Heading>
                            <Text mb={4}>
                                Removing hazardous trees can significantly reduce the risk of property damage and personal
                                injury. Professional tree removal helps maintain the beauty and safety of your surroundings.
                            </Text>
                            <List spacing={3} mb={4} p={0}>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Dangerous Trees pose a risk due to their potential to fall on property or people,
                                    especially
                                    during severe weather conditions.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Dead Trees: Dead or dying trees become weak and can easily topple over, posing risks to
                                    safety and property.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Diseased Trees: Trees infected with disease can spread the affliction to nearby healthy
                                    trees, jeopardizing the overall health of your landscape.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Obstructive Trees: Trees that interfere with construction property development or are in
                                    an
                                    undesirable location.
                                </ListItem>
                            </List>
                            <Heading as="h3" size="md" mb={4}>
                                Why Johnson City Tree Service Stands Out
                            </Heading>
                            <Text mb={4}>
                                Opting for Johnson City Tree Service for your tree removal needs ensures you get the
                                region's
                                best care. Here's why:
                            </Text>
                            <List spacing={3} mb={4} p={0}>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Expert Arborists: Our professional arborists are not only trained in safe tree removal
                                    techniques but also in assessing the health and potential risk of trees to provide
                                    informed
                                    advice and services.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Quality Service: We're licensed and insured, offering high-quality service that puts
                                    your
                                    satisfaction at the forefront. Our methods are safe, efficient, and practical, ensuring
                                    minimal disruption to your daily life.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Local Expertise: Understanding local botany and landscape nuances is crucial in tree
                                    removal
                                    services. Our local expertise ensures that we provide customized solutions that best fit
                                    the
                                    needs of your property and the Johnson City environment.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Customer Satisfaction: Your satisfaction is our priority. We strive to make the tree
                                    removal
                                    process as seamless and satisfactory as possible, from initial consultation to the final
                                    cleanup.
                                </ListItem>
                            </List>
                            <Heading as="h3" size="md" mb={4}>
                                Our Comprehensive Tree Removal Process
                            </Heading>
                            <Text mb={4}>
                                Our tree removal process involves a thorough assessment, safe cutting techniques, and
                                complete debris cleanup. We ensure the entire process is handled with attention to detail
                                and respect for your property and safety.
                            </Text>
                            <List spacing={3} mb={4} p={0}>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Initial Consultation: We start with a detailed inspection of your property to understand
                                    your needs and the condition of your trees.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Hazard Assessment: Our experts evaluate the risks the trees pose, considering factors
                                    like
                                    size, location, and health.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Removal Plan: We devise a strategic plan for safe removal, aiming to minimize any
                                    potential
                                    damage to your property.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Cutting and Removal: Using state-of-the-art equipment, our team carefully removes the
                                    tree,
                                    section by section if necessary.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Stump Grinding: After tree removal, we offer stump grinding to smooth your
                                    landscape for new developments.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Cleanup and Disposal: We ensure all debris is cleaned up and properly disposed of,
                                    leaving your property pristine.
                                </ListItem>
                            </List>

                            <Heading as="h2" size="lg" mb={3}>
                                FAQs About Tree Removal at Johnson City Nexus Tree Service
                            </Heading>
                            <Accordion allowToggle p={0}>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                What is involved in professional tree removal?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Professional tree removal includes the assessment of the tree's condition, the
                                        surrounding area, and determining the safest method for its removal. Our certified
                                        arborists use state-of-the-art equipment to cut and dismantle the tree
                                        systematically, ensuring safety throughout the process. The service also includes
                                        complete debris cleanup and disposal, restoring your property to its original
                                        condition.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                How do I know if a tree needs to be removed?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        A tree may need to be removed if it is dead, diseased, or posing a hazard to your
                                        property, such as interfering with power lines or structures. Trees that are
                                        unstable, especially those that have sustained damage from storms or are decayed,
                                        should also be evaluated for removal. Our experts can provide a comprehensive
                                        assessment to help you determine whether tree removal is necessary.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                Is tree removal covered by homeowners insurance?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Tree removal is generally covered by homeowners insurance if the tree falls due to a
                                        natural disaster, like a storm, and damages your house or other insured structures.
                                        However, if the tree falls due to neglect or disease without causing damage to
                                        insured structures, it typically is not covered. We recommend consulting with your
                                        insurance provider for specific details regarding your coverage.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                What precautions do you take to ensure safety during tree removal?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Safety is our top priority. We implement rigorous safety protocols, including
                                        securing the area, using appropriate personal protective equipment, and employing
                                        advanced rigging techniques to control the descent of the tree. Our team is
                                        extensively trained in these procedures to ensure a safe environment for both our
                                        staff and your property during the removal process.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                How long does it take to remove a tree?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        The time it takes to remove a tree can vary widely depending on the size and
                                        location of the tree, as well as the complexity of the job. A simple removal can
                                        take a few hours, while a larger or more complex project might take a full day or
                                        more. We will provide an estimated timeframe after assessing the tree and the site
                                        conditions.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                What do you do with the tree once it is removed?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        After a tree is removed, the wood can be chopped and left for you to use as
                                        firewood, or it can be hauled away. We also offer stump grinding services to
                                        completely clear the area. We aim to recycle as much of the tree as possible, either
                                        through mulching or other eco-friendly practices.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                Do I need a permit to remove a tree in Johnson City, TN?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Depending on the size and location of the tree, you may need a permit to remove a
                                        tree in Johnson City. We can help you determine if a permit is necessary and assist
                                        in the application process if required.
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                        <Heading as="h2" size="md" mb={4}>
                            Enhancing Your Property with Professional Tree Removal
                        </Heading>
                        <Text mb={4}>
                            Removing trees, especially those that are large or in complex locations, is about more than
                            just
                            cutting them down. It’s about enhancing your property's safety, health, and aesthetic
                            appeal.
                            With Johnson City Tree Service, you gain a partner dedicated to the beauty and protection of
                            your landscape. Our services remove immediate hazards and prepare your property for future
                            growth and development.
                        </Text>
                        <Text mb={8}>
                            In Johnson City, TN, where the beauty of nature is integral to the community's charm,
                            maintaining a healthy and safe landscape is essential. Johnson City Tree Service offers
                            comprehensive, professional tree removal services tailored to the unique needs of your
                            property.
                            From dangerous tree removal to clearing space for new projects, our expert team ensures that
                            your property remains a safe, beautiful, and thriving environment.
                        </Text>
                        <Box display="flex">
                            <Button colorScheme="green" as={Link} href="tel:423-840-8746">
                                Call us at 423-840-8746
                            </Button>
                        </Box>
                    </Container>
                ),
        }
        ,
        treeTrimming: {
            url: "https://www.johnsoncitytreeservice.com/tree-trimming-johnson-city/",
            metaData:
                {
                    title: "Expert Tree Trimming Johnson City, TN | Call Us: (423) 840-8746",
                    description:
                        "Enhance the health and beauty of your trees with top-tier tree trimming services in Johnson City, TN. Contact us at (423) 840-8746 for expert care.",
                }
            ,
            h1: `Best Tree Trimming Johnson City, TN`,
            subtitle:
                "Healthy, well-trimmed trees can significantly enhance the beauty and value of your property. At Johnson City Tree Service, we offer professional tree trimming services designed to keep your trees healthy and vibrant throughout the year.",
            imgAlt:
                "tree trimming johnson city tn",
            body:
                (
                    <Box>
                        <Box p={5}>
                            <Heading as="h2" size="xl" mb={4}>
                                Professional Tree Trimming Services in Johnson City, TN
                            </Heading>
                            <Text fontSize="lg" mb={4}>
                                Welcome to Johnson City Nexus Tree Service, where we bring expert tree care to your
                                doorstep. Based in the heart of Johnson City, Tennessee, our company specializes in
                                providing top-tier tree trimming services to improve the beauty and health of your trees.
                                Our team of arborists are equipped with the latest tools to ensure that your trees are
                                trimmed and thoroughly cared for. Whether you are looking to boost curb appeal, clear
                                branches away from power lines, or maintain healthy tree growth, our professionals are here
                                to help. Trust us to keep your trees looking their best all year round with our reliable and
                                efficient tree-trimming solutions.
                            </Text>
                            <Heading as="h2" size="lg" mb={3}>
                                Why Choose Us?
                            </Heading>
                            <Text mb={4}>
                                At Johnson City Nexus Tree Service, we know the importance of preserving and maintaining the
                                health and aesthetics of your landscape. Our extensive experience in tree trimming and
                                arboriculture sets us apart from the competition. We are committed to delivering exceptional
                                results with a focus on safety and efficiency. Our arborists are certified and regularly
                                trained on the latest safety protocols and tree care techniques. We pride ourselves on our
                                dedication to implementing environmentally friendly practices and our ability to offer
                                customized solutions to meet our client's unique needs. Choose us because, at Johnson City
                                Nexus Tree Service, we treat your yard like our own, ensuring that every tree we touch is
                                pruned and cared for.
                            </Text>
                            <Heading as="h2" size="lg" mb={3}>
                                Our Tree Trimming Services
                            </Heading>
                            <List spacing={3} mb={4}>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Residential Tree Trimming: Our residential tree trimming services are designed to
                                    enhance your home's outdoor space while ensuring the health of your trees. Regular
                                    trimming helps prevent diseases and promotes vigorous growth. We can craft our services
                                    to meet the specific needs of each tree, from young saplings needing formative pruning
                                    to mature trees requiring deadwood removal.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Commercial Tree Trimming: For our commercial clients in Johnson City, we provide robust
                                    tree trimming services that not only ensure the safety of your premises but also enhance
                                    the visual appeal of your business environment. Well-maintained trees can significantly
                                    boost your business's impression on clients and visitors.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color="green.500"/>
                                    Emergency Tree Trimming and Removal: Storms and severe weather can cause unexpected
                                    damage to your trees. Johnson City Nexus Tree Service offers emergency tree trimming and
                                    removal services to address these urgent needs swiftly and safely. Our team is on call
                                    24/7 to respond to your emergencies, minimizing risks and preventing further damage to
                                    your property.
                                </ListItem>
                            </List>
                            <Heading as="h2" size="lg" mb={3}>
                                The Tree Trimming Process
                            </Heading>
                            <Text mb={4}>
                                At Johnson City Nexus Tree Service, our tree trimming process begins with thoroughly
                                assessing your trees and property to identify specific needs and potential challenges. Our
                                certified arborists then create a tailored plan that addresses immediate concerns and
                                promotes long-term health and growth. We use specialized equipment to safely trim overgrown
                                branches, remove hazardous limbs, and shape your trees aesthetically. Each step of our
                                process is conducted with the ultimate care for your property and the environment, ensuring
                                that no detail is overlooked.
                            </Text>
                            <Heading as="h2" size="lg" mb={3}>
                                Benefits of Tree Trimming
                            </Heading>
                            <Text mb={4}>
                                Tree trimming is not just about improving the appearance of your trees; it's a crucial part
                                of maintaining their health and vitality. Regular trimming helps prevent the spread of
                                diseases and pests, encourages productive growth, and reduces the risk of falling branches.
                                It also enhances the overall safety of your property by eliminating hazards that could
                                damage your home or injure someone. Additionally, well-maintained trees add to your
                                property's aesthetic value and curb appeal, potentially increasing its market value.
                            </Text>
                            <Heading as="h2" size="lg" mb={3}>
                                FAQs About Tree Trimming at Johnson City Nexus Tree Service
                            </Heading>
                            <Accordion allowToggle mb={5}>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                What is tree trimming, and why is it necessary?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Tree trimming, also known as pruning, involves the selective removal of certain tree
                                        parts, such as branches, buds, or roots. It's essential for maintaining tree health,
                                        ensuring safety, and enhancing aesthetic appeal. Regular trimming helps prevent
                                        disease, encourages healthy growth, and reduces the risk of branch failures that
                                        could damage property or cause injuries.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                When is the best time to trim trees in Johnson City, TN?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        The best time for tree trimming varies depending on the tree type and the specific
                                        pruning goals. Generally, the end of winter or early spring is ideal, as trees are
                                        dormant, and it's easier to see the tree structure without leaves. However, some
                                        species may require different timing to ensure optimal health and blooming. Our
                                        arborists can advise you on the best tree pruning schedule.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                How often should I have my trees trimmed?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        How often you should trim your trees depends on the species, size, and health of the
                                        tree, as well as the landscape objectives. Most trees benefit from being trimmed
                                        every one to three years, but ornamental trees might need more frequent pruning. We
                                        can provide a customized trimming schedule that keeps your trees in top condition.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                Is tree trimming safe to do by myself?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Tree trimming can be hazardous, especially for large trees or those near power
                                        lines. It requires proper safety gear and knowledge of tree biology to avoid harming
                                        the tree or yourself. For these reasons, we recommend professional tree trimming
                                        services, especially for complex or high-risk situations. Our Johnson City Nexus
                                        Tree Service team is equipped with the expertise and equipment to ensure safe and
                                        effective trimming.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                What are the risks of not trimming my trees?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Neglecting tree trimming can lead to problems such as overgrowth, disease, and
                                        weakened branches that are more susceptible to breaking in storms. This can risk
                                        safety, property, and the tree's health. Regular maintenance helps to manage these
                                        risks and can prolong the life of your trees.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                How does professional tree trimming differ from DIY trimming?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Professional tree trimming ensures that the pruning is done correctly, considering
                                        the tree's health and overall safety. Professionals use specialized tools and
                                        techniques to make precise cuts that promote healthy growth. Without the proper
                                        knowledge or tools, DIY trimming can lead to mistakes that permanently damage the
                                        tree or even result in personal injury.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                What should I expect during a tree trimming service visit?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        During a service visit from Johnson City Nexus Tree Service, our team will first
                                        assess the condition of your trees and discuss your specific needs and goals. We
                                        will then carefully trim the necessary branches, not harming the tree or surrounding
                                        property. All debris will be cleaned and removed from the site, leaving your
                                        landscape neat.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                How much does tree trimming cost in Johnson City, TN?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        The cost of tree trimming services varies depending on the size and number of trees,
                                        their condition, and the job's complexity. We offer competitive pricing and provide
                                        a comprehensive estimate after an on-site assessment. We aim to offer our clients
                                        affordable, high-quality tree care services in Johnson City.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                Can tree trimming help my fruit trees produce more fruit?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Yes, proper pruning is crucial for fruit trees to maintain their shape and health
                                        and improve fruit production. Trimming helps to increase sunlight penetration and
                                        air circulation, which are essential for fruit development and can help prevent
                                        diseases that affect fruit quality and quantity.
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                Do you clean up after trimming?
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        Absolutely. Part of our tree trimming service includes comprehensive cleanup to
                                        ensure that your property remains neat and debris-free. We take pride in leaving
                                        your landscape cleaner than when we arrived.
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                            <Heading as="h2" size="lg" mb={3}>
                                Contact Us
                            </Heading>
                            <Text fontSize="lg" mb={4}>
                                Ready to improve the health and looks of your trees? Contact Johnson City Nexus Tree Service
                                today! Our friendly team is prepared to answer any questions and schedule a consultation.
                                Whether you need routine trimming or emergency tree care, we are here to provide the highest
                                quality service in Johnson City, TN.
                            </Text>
                            <Button colorScheme="green" size="lg">
                                Schedule a Consultation
                            </Button>
                        </Box>
                    </Box>
                ),
        }
        ,
        stumpGrinding: {
            url: "Stump Grinding Services in Johnson City, TN | Johnson City Tree Service",
            metaData:
                {
                    title:
                        "Efficient Stump Grinding in Johnson City, TN | Call Us: (423) 840-8746",
                    description:
                        "Eliminate unsightly tree stumps with our efficient stump grinding services in Johnson City, TN. Call Johnson City Tree Service for a free quote!",
                }
            ,
            h1: "Expert Stump Grinding in Johnson City, TN",
            subtitle:
                "If you've recently had a tree removed or have an old tree stump that's become an eyesore on your landscape, Johnson City Tree Service has the solution for you. We provide professional and efficient stump grinding services in Johnson City, TN, and the surrounding area.",
            body:
                (
                    <Box>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Efficient Stump Grinding Services for Seamless Removal
                            </Heading>
                            <Text>
                                At Johnson City Tree Service, we provide a comprehensive suite of
                                stump grinding services, helping you reclaim your landscape. Here's
                                what we offer:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Stump Grinding:</b> Our expert team can grind any stump,
                                    regardless of its size or location, effectively reducing it below
                                    ground level.
                                </ListItem>
                                <ListItem>
                                    <b>Stump Debris Removal:</b> After grinding, we ensure the cleanup
                                    and removal of stump debris, leaving your property clean and tidy.
                                </ListItem>
                                <ListItem>
                                    <b>Landscape Restoration:</b> Post-grinding, we offer restoration
                                    services to repair the area, including soil preparation and
                                    replanting if desired.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Why Choose Johnson City Tree Service for Expert Stump Grinding
                                Solutions?
                            </Heading>
                            <Text>
                                With a deep understanding of local landscaping needs and a proven
                                track record, here's why Johnson City Tree Service is the right
                                choice for your stump grinding needs:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Experienced Professionals:</b> Our team of professionals
                                    possess the skills and equipment necessary to handle any stump
                                    grinding job efficiently.
                                </ListItem>
                                <ListItem>
                                    <b>Quality Service:</b> We are committed to providing high-quality
                                    services that meet your satisfaction. Our reputation and positive
                                    customer reviews attest to this!
                                </ListItem>
                                <ListItem>
                                    <b>Local Expertise:</b> Our team's knowledge of the local terrain
                                    and botany enables us to provide tailored stump grinding solutions
                                    for your property.
                                </ListItem>
                                <ListItem>
                                    <b>Customer Satisfaction:</b> Our top priority is ensuring a
                                    smooth, hassle-free experience for our customers, resulting in
                                    their complete satisfaction with our services.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Superior Stump Grinding Services in Johnson City, TN for Seamless
                                Removal
                            </Heading>
                            <Text>
                                Reach out to us today at{" "}
                                <Link href={telLink}>{phoneNumberWithDashes}</Link> to schedule your
                                stump grinding service. Experience the Johnson City Tree Service
                                difference!
                            </Text>
                        </Stack>
                    </Box>
                ),
        }
        ,
        treeHealthConsulting: {
            url: "https://www.johnsoncitytreeservice.com/tree-health-consulting-johnson-city",
            metaData:
                {
                    title:
                        "Tree Health Consulting in Johnson City, TN - Johnson City Tree Service",
                    description:
                        "Johnson City Tree Service offers expert tree health consulting services, helping you maintain the health and beauty of your trees. Contact us today.",
                }
            ,
            h1: "Comprehensive Tree Health Consulting in Johnson City, TN",
            subtitle:
                "Understanding the health of your trees can be a complex task. That's where we, at Johnson City Tree Service, come in. Our experienced arborists offer comprehensive tree health consulting services designed to ensure the well-being of your trees.",
            body:
                (
                    <Box>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Expert Tree Health Consulting Services for Optimal Wellness
                            </Heading>
                            <Text>
                                At Johnson City Tree Service, we are devoted to ensuring the
                                vitality and longevity of your trees. Here’s what our tree health
                                consulting services include:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Health Assessment:</b> Our team of arborists will conduct a
                                    thorough inspection of your trees to identify any signs of
                                    disease, pest infestation, or other potential problems.
                                </ListItem>
                                <ListItem>
                                    <b>Nutrition Plan:</b> We develop a comprehensive nutrition plan
                                    tailored to your specific trees, ensuring they receive all the
                                    essential nutrients for healthy growth.
                                </ListItem>
                                <ListItem>
                                    <b>Disease and Pest Management:</b> Our arborists provide expert
                                    advice and services for managing tree diseases and pest
                                    infestations, helping to protect your trees.
                                </ListItem>
                                <ListItem>
                                    <b>Tree Care Education:</b> We provide education on tree care
                                    practices, helping you better understand how to maintain the
                                    health of your trees in between professional services.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Why Choose Johnson City Tree Service for Your Tree Health Consulting
                                Needs?
                            </Heading>
                            <Text>
                                With deep roots in the Johnson City community and a proven track
                                record, here's why Johnson City Tree Service is the ideal choice for
                                your tree health consulting needs:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Certified Arborists:</b> Our team of certified arborists bring
                                    their wealth of knowledge and expertise to every consultation.
                                </ListItem>
                                <ListItem>
                                    <b>Customized Solutions:</b> We provide personalized solutions for
                                    your unique tree care needs, ensuring optimal health and growth
                                    for your trees.
                                </ListItem>
                                <ListItem>
                                    <b>Local Knowledge:</b> Our team's familiarity with the local
                                    flora makes us particularly equipped to handle your tree health
                                    concerns.
                                </ListItem>
                                <ListItem>
                                    <b>Customer Satisfaction:</b> Ensuring a seamless experience for
                                    our customers is our top priority. We're committed to meeting and
                                    exceeding your expectations.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Top-Tier Tree Health Consulting Services in Johnson City, TN
                            </Heading>
                            <Text>
                                Get in touch with us today at{" "}
                                <Link href={telLink}>{phoneNumberWithDashes}</Link> to schedule your
                                tree health consultation. Experience the Johnson City Tree Service
                                difference!
                            </Text>
                        </Stack>
                    </Box>
                ),
        }
        ,
        landClearing: {
            url: "https://www.johnsoncitytreeservice.com/land-clearing-johnson-city",
            metaData:
                {
                    title:
                        "Land Clearing Services | Johnson City Tree Service",
                    description:
                        "Professional land clearing services in Johnson City, TN. Contact Johnson City Tree Service for efficient and eco-friendly solutions!",
                }
            ,
            h1: "Professional Land Clearing Services in Johnson City, TN",
            subtitle:
                "Whether you're preparing for construction, landscaping, or agricultural projects, Johnson City Tree Service offers comprehensive land clearing services in Johnson City, TN, and the surrounding areas.",
            body:
                (
                    <Box>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Efficient Land Clearing Services for Your Property Transformation
                            </Heading>
                            <Text>
                                At Johnson City Tree Service, we specialize in efficient land
                                clearing solutions to help you prepare your property for its next
                                phase. Our services include:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Tree and Brush Removal:</b> We safely and effectively remove
                                    trees, shrubs, and undergrowth, ensuring a clear site for your
                                    project.
                                </ListItem>
                                <ListItem>
                                    <b>Stump Grinding and Removal:</b> Following tree removal, we
                                    provide stump grinding services to leave your land smooth and
                                    ready for development.
                                </ListItem>
                                <ListItem>
                                    <b>Debris Clearing and Disposal:</b> We handle the cleanup and
                                    responsible disposal of all cleared vegetation and debris.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Why Choose Johnson City Tree Service for Land Clearing?
                            </Heading>
                            <Text>
                                Our expertise in land clearing sets us apart. Here's why you should
                                choose us for your land preparation needs:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Comprehensive Approach:</b> We offer a full suite of services,
                                    from initial assessment to final clearing, tailored to your
                                    project's specific requirements.
                                </ListItem>
                                <ListItem>
                                    <b>Eco-Friendly Practices:</b> Our methods prioritize the
                                    environment, ensuring minimal impact and sustainable land use.
                                </ListItem>
                                <ListItem>
                                    <b>Professional Equipment:</b> We use state-of-the-art machinery
                                    and techniques to deliver efficient and safe land clearing
                                    services.
                                </ListItem>
                                <ListItem>
                                    <b>Experienced Team:</b> Our skilled professionals are trained in
                                    the latest land clearing methods, ensuring high-quality work.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Kickstart Your Land Clearing Project Today with Johnson City Tree
                                Service
                            </Heading>
                            <Text>
                                Contact us at <Link href={telLink}>{phoneNumberWithDashes}</Link>{" "}
                                for a consultation or to schedule land clearing services. Trust
                                Johnson City Tree Service for reliable, high-quality land clearing
                                in Johnson City, TN.
                            </Text>
                        </Stack>
                    </Box>
                ),
        }
        ,
        arboristConsulting: {
            url: "https://www.johnsoncitytreeservice.com/arborist-consultations-johnson-city",
            metaData:
                {
                    title:
                        "Expert Arborist Consultations | Johnson City Tree Service",
                    description:
                        "Discover how our advanced arboriculture techniques are revolutionizing tree care in Johnson City, TN. Contact us for expert tree services today.",
                }
            ,
            h1: "Expert Arborist Consultation Services in Johnson City, TN",
            subtitle:
                "At Johnson City Tree Service, our certified arborists provide expert consulting services to help you understand and manage the trees on your property effectively.",
            body:
                (
                    <Box>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Comprehensive Arborist Consulting Services for Tree Care Excellence
                            </Heading>
                            <Text>
                                We offer a range of consulting services to address various aspects
                                of tree care and management. Our services include:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Tree Health Assessments:</b> Comprehensive evaluations to
                                    determine the health of your trees and identify any potential
                                    issues.
                                </ListItem>
                                <ListItem>
                                    <b>Tree Care Recommendations:</b> Expert advice on pruning,
                                    fertilization, pest control, and other maintenance practices to
                                    keep your trees healthy and thriving.
                                </ListItem>
                                <ListItem>
                                    <b>Landscape Planning:</b> Assistance in planning and managing
                                    your landscape to ensure the right tree is planted in the right
                                    place.
                                </ListItem>
                                <ListItem>
                                    <b>Risk Assessment:</b> Identifying potential hazards related to
                                    trees on your property and recommending appropriate measures to
                                    mitigate risks.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>Why Choose Our Arborist Consulting?</Heading>
                            <Text>
                                Our certified arborists bring a wealth of knowledge and experience
                                to every consultation. Here's why Johnson City Tree Service is the
                                go-to for arborist consulting:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Certified and Experienced:</b> Our team consists of certified
                                    arborists with extensive experience in tree biology, care, and
                                    management.
                                </ListItem>
                                <ListItem>
                                    <b>Personalized Approach:</b> We provide tailored recommendations
                                    based on the unique needs of your trees and landscape.
                                </ListItem>
                                <ListItem>
                                    <b>Comprehensive Knowledge:</b> Our arborists stay updated on the
                                    latest research and techniques in arboriculture to offer the best
                                    advice.
                                </ListItem>
                                <ListItem>
                                    <b>Commitment to Sustainability:</b> We emphasize eco-friendly
                                    practices and sustainable landscape management in all our
                                    recommendations.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>
                                Book Your Arborist Consultation Today for Expert Tree Care Insights
                            </Heading>
                            <Text>
                                Ready to enhance the health and beauty of your trees? Contact us at{" "}
                                <Link href={telLink}>{phoneNumberWithDashes}</Link> to schedule an
                                arborist consultation and discover how we can help you achieve a
                                thriving landscape.
                            </Text>
                        </Stack>
                    </Box>
                ),
        }
        ,
        treePlanting: {
            url: "https://www.johnsoncitytreeservice.com/tree-planting-johnson-city",
            metaData:
                {
                    title:
                        "Tree Planting Services Johnson City, TN | Johnson City Tree Service",
                    description:
                        "Enhance your landscape with our expert tree planting services in Johnson City, TN. Choose Johnson City Tree Service for sustainable, healthy tree growth.",
                }
            ,
            h1: "Tree Planting Services in Johnson City, TN",
            subtitle:
                "Johnson City Tree Service offers professional tree planting services, ensuring the health and beauty of your landscape for years to come.",
            body:
                (
                    <Box>
                        <Stack spacing={3}>
                            <Text>
                                Planting a tree is more than just enhancing the aesthetics of your
                                landscape; it's an investment in the environment and future. At
                                Johnson City Tree Service, we specialize in tree planting services
                                that cater to the unique climate and soil conditions of Johnson
                                City, TN. Our expert team will help you choose the right trees for
                                your property and ensure they are planted correctly for optimal
                                growth and health.
                            </Text>
                            <Heading as={"h2"}>Why Opt for Professional Tree Planting?</Heading>
                            <UnorderedList>
                                <ListItem>
                                    Correct Planting Techniques: Ensuring trees are planted at the
                                    right depth and with proper spacing is crucial for their survival
                                    and growth.
                                </ListItem>
                                <ListItem>
                                    Selection of Suitable Species: We help select tree species that
                                    will thrive in the local climate and soil conditions of Johnson
                                    City.
                                </ListItem>
                                <ListItem>
                                    Future Growth Planning: Our experts consider the future growth of
                                    the tree, ensuring it won't interfere with structures, power
                                    lines, or other plants.
                                </ListItem>
                            </UnorderedList>
                            <Heading as={"h2"}>Our Tree Planting Process</Heading>
                            <Text>
                                We take a comprehensive approach to tree planting, which includes:
                            </Text>
                            <UnorderedList>
                                <ListItem>
                                    Site Assessment: Evaluating the planting site for sunlight, soil
                                    type, and drainage.
                                </ListItem>
                                <ListItem>
                                    Tree Selection: Advising on the best tree species based on your
                                    aesthetic goals and the site's conditions.
                                </ListItem>
                                <ListItem>
                                    Professional Planting: Using the best practices to ensure your new
                                    trees have a healthy start.
                                </ListItem>
                                <ListItem>
                                    Post-Planting Care: Providing guidance on watering, mulching, and
                                    ongoing care for the newly planted trees.
                                </ListItem>
                            </UnorderedList>
                            <Text>
                                Contact Johnson City Tree Service today to learn more about our tree
                                planting services and how we can help enhance your property's
                                landscape.
                            </Text>
                        </Stack>
                    </Box>
                ),
        }
        ,
        emergencyTreeServices: {
            url: "https://www.johnsoncitytreeservice.com/emergency-tree-services-johnson-city",
            metaData:
                {
                    title:
                        "Emergency Tree Services in Johnson City, TN",
                    description:
                        "Immediate response for emergency tree services in Johnson City, TN. Contact Johnson City Tree Service for urgent tree care and removal.",
                }
            ,
            h1: "Emergency Tree Services in Johnson City, TN",
            subtitle:
                "In times of unexpected tree emergencies, Johnson City Tree Service offers fast, reliable, and safe emergency tree care services in Johnson City, TN, and the surrounding areas.",
            body:
                (
                    <Box>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>Our Arborist Consulting Services</Heading>
                            <Text>
                                We offer a range of consulting services to address various aspects
                                of tree care and management. Our services include:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Tree Health Assessments:</b> Comprehensive evaluations to
                                    determine the health of your trees and identify any potential
                                    issues.
                                </ListItem>
                                <ListItem>
                                    <b>Tree Care Recommendations:</b> Expert advice on pruning,
                                    fertilization, pest control, and other maintenance practices to
                                    keep your trees healthy and thriving.
                                </ListItem>
                                <ListItem>
                                    <b>Landscape Planning:</b> Assistance in planning and managing
                                    your landscape to ensure the right tree is planted in the right
                                    place.
                                </ListItem>
                                <ListItem>
                                    <b>Risk Assessment:</b> Identifying potential hazards related to
                                    trees on your property and recommending appropriate measures to
                                    mitigate risks.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>Why Choose Our Arborist Consulting?</Heading>
                            <Text>
                                Our certified arborists bring a wealth of knowledge and experience
                                to every consultation. Here's why Johnson City Tree Service is the
                                go-to for arborist consulting:
                            </Text>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    <b>Certified and Experienced:</b> Our team consists of certified
                                    arborists with extensive experience in tree biology, care, and
                                    management.
                                </ListItem>
                                <ListItem>
                                    <b>Personalized Approach:</b> We provide tailored recommendations
                                    based on the unique needs of your trees and landscape.
                                </ListItem>
                                <ListItem>
                                    <b>Comprehensive Knowledge:</b> Our arborists stay updated on the
                                    latest research and techniques in arboriculture to offer the best
                                    advice.
                                </ListItem>
                                <ListItem>
                                    <b>Commitment to Sustainability:</b> We emphasize eco-friendly
                                    practices and sustainable landscape management in all our
                                    recommendations.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack spacing={3} py={5}>
                            <Heading as={"h2"}>Schedule Your Arborist Consultation Today</Heading>
                            <Text>
                                Ready to enhance the health and beauty of your trees? Contact us at{" "}
                                <Link href={telLink}>{phoneNumberWithDashes}</Link> to schedule an
                                arborist consultation and discover how we can help you achieve a
                                thriving landscape.
                            </Text>
                        </Stack>
                    </Box>
                ),
        }
        ,
        404:
            {
                metaData: {
                    title: "Page Not Found - Johnson City Tree Service | Tree Service",
                    description:
                        "Oops! The page you're seeking could not be found. Johnson City Tree Service offers professional tree care services. Reach out to us for reliable help.",
                    siteName:
                        "Tree Service in Johnson City, TN",
                }
                ,
                h1: "404 - Oops, you found a page that's missing its leaves.",
                subtitle:
                    "Regrettably, the page you are hunting for does not exist.",
                p:
                    "Whether you require tree trimming, stump removal, or any other tree care service, we've got you covered. Call us for your tree care needs.",
            }
        ,
    }
;
